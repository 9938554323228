"use client";
import ChevronDown from "@/components/icons/ChevronDown";
import Close from "@/components/icons/Close";
import { ColumnsIcon } from "@/components/icons/Columns";
import Plus from "@/components/icons/Plus";
import Button from "@/components/ui/Button";
import useDevice from "@/hooks/useDevice";
import {
  ITags,
  SortOrder,
} from "@/lib/centra/hooks/useProductFilter/useProductFilter";
import clsx from "clsx";
import FilterButton from "../PlpButtons/FilterButton";
import SortButton from "../PlpButtons/SortButton";
import SortModal from "../PlpModals/Sort";
import styles from "./index.module.css";

type Props = {
  productCount?: number;
  tags?: ITags;
  gridSize: number;
  setGridSize: React.Dispatch<React.SetStateAction<number>>;
  sortOrder: SortOrder;
};

const PlpTopBar = ({
  productCount,
  tags,
  setGridSize,
  gridSize,
  sortOrder,
}: Props) => {
  const hasTags = tags && tags.length > 0;
  const filterCount = tags?.length ?? 0;
  const sortByDisplay = sortOrder?.active?.display;
  const hasSortBy = sortOrder?.sortOrder.length > 0;

  const { mobile } = useDevice();

  return (
    <section className={styles.filters}>
      <div className={styles.wrap}>
        <div className={styles.tagWrapper}>
          {hasTags && (
            <div className={styles.tags}>
              {tags?.map((tag, key) => (
                <span
                  tabIndex={1}
                  className={styles.tag}
                  key={key}
                  onClick={tag.toggle}
                  role="button"
                >
                  <span>{tag.value}</span>
                  <Close />
                </span>
              ))}
            </div>
          )}
        </div>
        <div className={styles.count}>
          {productCount} {productCount && productCount > 1 ? "items" : "item"}
        </div>

        <div className={styles.filtersBar}>
          <FilterButton>
            <span>
              <span className={clsx(hasTags && styles.bold)}>
                Filter
                {filterCount > 0 && <span>({filterCount})</span>}
              </span>
              {!hasTags && <Plus />}
            </span>
          </FilterButton>
        </div>
        <div className={styles.sortBy}>
          <SortButton>
            <span>
              <span>Sort by</span>
              {hasSortBy && (
                <span className={styles.bold}>/{sortByDisplay}</span>
              )}
              <ChevronDown />
            </span>
          </SortButton>
          <SortModal sortOrder={sortOrder} />
        </div>

        <div className={styles.colbuttons}>
          <Button
            mode="toggle"
            title={mobile ? "Two columns" : "Four columns"}
            onClick={() => {
              setGridSize(4);
            }}
          >
            <div
              className={clsx(
                styles.colbutton,
                gridSize === 4 && styles.active,
              )}
            >
              <ColumnsIcon className="desktop" columns={4} />
              <ColumnsIcon className="mobile" columns={2} />
            </div>
          </Button>

          <Button
            mode="toggle"
            title={mobile ? "Single column" : "Three columns"}
            onClick={() => {
              setGridSize(3);
            }}
          >
            <div
              className={clsx(
                styles.colbutton,
                gridSize === 3 && styles.active,
              )}
            >
              <ColumnsIcon className="desktop" columns={3} />
              <ColumnsIcon className="mobile" columns={1} />
            </div>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default PlpTopBar;
