"use client";
import clsx from "clsx";
import type { Dispatch, SetStateAction } from "react";

import Popover from "@/components/ui/Popover";
import type { ISortOption, ISortOrderValue } from "@/lib/types/centra";

import Button from "@/components/ui/Button";
import styles from "./sort-modal.module.css";

const SortModal = ({
  sortOrder
}: {
  sortOrder: {
    setSortOrder: Dispatch<SetStateAction<ISortOrderValue[]>>;
    options: ISortOption[];
    active: ISortOption | undefined;
  };
}) => {
  //const { closeModal } = useModal("sort", () => {});
  return (
    <Popover modal={"sort"} className={styles.modal}>
      <div className={styles.modal__content}>
        <div className={styles["sort-options"]}>
          {sortOrder.options.map((option) => {
            return (
              <div
                className={clsx(
                  styles["sort-option"],
                  option.active && styles.active
                )}
                key={option.key}
              >
                <Button
                  mode="unset"
                  onClick={() => {
                    option.setFunction();
                  }}
                >
                  {option.display}
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </Popover>
  );
};

export default SortModal;
